$(function () {
    // SmartMenus init
    $('#main-menu').smartmenus({
        subMenusSubOffsetX: 1,
        subMenusSubOffsetY: -8
    });
    // SmartMenus mobile menu toggle button
    var $mainMenuState = $('#main-menu-state');
    if ($mainMenuState.length) {
        // animate mobile menu
        $mainMenuState.change(function (e) {
            var $menu = $('#main-menu');
            if (this.checked) {
                $menu.hide().slideDown(250, function () {
                    $menu.css('display', '');
                });
            } else {
                $menu.show().slideUp(250, function () {
                    $menu.css('display', '');
                });
            }
        });
        // hide mobile menu beforeunload
        $(window).bind('beforeunload unload', function () {
            if ($mainMenuState[0].checked) {
                $mainMenuState[0].click();
            }
        });
    }
    var swiper = new Swiper('.swiper-container', {
        slidesPerView: 1,
        spaceBetween: 20,
        slidesPerGroup: 1,
        loop: true,
        loopFillGroupWithBlank: true,
        autoplay: {
            delay: 3500,
            disableOnInteraction: false,
        }
    });
    $(".swiper-container").hover(function () {
        swiper.autoplay.stop();
    }, function () {
        swiper.autoplay.start();
    });
    var topBtn = $('.backtotop');
    topBtn.hide();
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            topBtn.fadeIn();
        } else {
            topBtn.fadeOut();
        }
    });
    topBtn.click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });
    if ($(".email-encrypted").length) {
        $(".email-encrypted").each(function () {
            var _text = $(this).text();
            if (_text.length > 0) {
                var _text_array = _text.split(',');
                var decrypted = '';
                $.each(_text_array, function (index, char) {
                    decrypted += String.fromCharCode(parseInt(char) + 1);
                });
                $(this).html('<a href="ma' + 'ilto:' + decrypted + '\">' + '<i class="fa fa-envelope"></i>' + "</a>");
            } else {
                $(this).html('<i class="fa fa-envelope"></i>');
            }

        });
    }
    if ($(".email-encrypted-text").length) {
        $(".email-encrypted-text").each(function () {
            var _text = $(this).text();
            if (_text.length > 0) {
                var _text_array = _text.split(',');
                var decrypted = '';
                $.each(_text_array, function (index, char) {
                    decrypted += String.fromCharCode(parseInt(char) + 1);
                });
                $(this).html('<a href="ma' + 'ilto:' + decrypted + '\">' + decrypted + "</a>");
            }
        });
    }
});
